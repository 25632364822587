<template>
    <div>
        <b-table small hover :items="itemsList" :fields="columns" class="thisTable" responsive>
            <template #cell(edit)="data">
                <b-button v-if="!data.item.isEditing" @click="data.item.isEditing = true" size="sm"><b-icon icon="pencil-fill"/></b-button>
                <b-button v-if="data.item.isEditing" @click="data.item.isEditing = false" size="sm"><b-icon icon="" /></b-button>
                <b-button v-if="data.item.isEditing" @click="validate(data.item)"></b-button>
            </template>

            <template #cell(competitionType)="data">
                <span class="competition-type">{{ data.item.COMPETITION_TYPE.name }}</span>
            </template>

            <template #cell(scope)="data">
                <span class="scope">{{ data.item.SCOPE.name }}</span>
            </template>

            <template #cell(judge) = "data">
                <b-checkbox size="lg" v-if="data.item.isJudge || data.item.isJudgeShadow" v-model = "data.item.isJudge" @change.native="selectRole(data.item, 'judge')"></b-checkbox>
            </template>

            <template #cell(techJudge) = "data">
                <b-checkbox size="lg" v-if="data.item.isTechJudge || data.item.isTechJudgeShadow" v-model = "data.item.isTechJudge" @change.native="selectRole(data.item, 'tech_judge')"></b-checkbox>
            </template>

            <template #cell(artJudge) = "data">
                <b-checkbox size="lg" v-if="data.item.isArtJudge || data.item.isArtJudgeShadow" v-model = "data.item.isArtJudge" @change.native="selectRole(data.item, 'art_judge')"></b-checkbox>
            </template>

            <template #cell(fieldDirector) = "data">
                <b-checkbox size="lg" v-if="data.item.isFieldDirector || data.item.isFieldDirectorShadow" v-model = "data.item.isFieldDirector" @change.native="selectRole(data.item, 'field_director')"></b-checkbox>
            </template>

            <template #cell(scorer) = "data">
                <b-checkbox size="lg" v-if="data.item.isScorer || data.item.isScorerShadow" v-model = "data.item.isScorer" @change.native="selectRole(data.item, 'scorer')"></b-checkbox>
            </template>

            <template #cell(teacher) = "data">
                <b-checkbox size="lg" v-if="data.item.isTeacher || data.item.isTeacherShadow" v-model = "data.item.isTeacher" @change.native="selectRole(data.item, 'teacher')"></b-checkbox>
            </template>

            <template #cell(judgeShadow) = "data">
                <b-checkbox size="lg" v-model = "data.item.isJudgeShadow" @change.native="selectRole(data.item, 'judge_shadow')"></b-checkbox>
            </template>

            <template #cell(techJudgeShadow) = "data">
                <b-checkbox size="lg" v-model = "data.item.isTechJudgeShadow" @change.native="selectRole(data.item, 'tech_judge_shadow')"></b-checkbox>
            </template>

            <template #cell(artJudgeShadow) = "data">
                <b-checkbox size="lg" v-model = "data.item.isArtJudgeShadow" @change.native="selectRole(data.item, 'art_judge_shadow')"></b-checkbox>
            </template>

            <template #cell(fieldDirectorShadow) = "data">
                <b-checkbox size="lg" v-model = "data.item.isFieldDirectorShadow" @change.native="selectRole(data.item, 'field_director_shadow')"></b-checkbox>
            </template>

            <template #cell(scorerShadow) = "data">
                <b-checkbox size="lg" v-model = "data.item.isScorerShadow"  @change.native="selectRole( data.item, 'scorer_shadow')"></b-checkbox>
            </template>
            <template #cell(teacherShadow) = "data">
                <b-checkbox size="lg" v-model = "data.item.isTeacherShadow"  @change.native="selectRole( data.item, 'teacher_shadow')"></b-checkbox>
            </template>
        </b-table>
    </div>
</template>

<script>
import { REF_SCOPE, REF_COMPETITION_TYPE } from "@/LocalDB";

export default {
    props:{
//        canEdit: { type: Boolean, default:false},
        showCompetitionType: { type: Boolean, default: true},
        showScope: { type:Boolean, default: true },
        showTechAndArtForJudge: { type: Boolean, default: false},
        items: { type: Array},
//        checkForSelectionCheckboxAccessibility : { type: Boolean, default: false },
    },
    computed:{
        columns(){
            var ret = [];
            if(this.showCompetitionType)
              ret.push({ key: "competitionType", sortable:true, label: 'Type de compétition'});
            if(this.showScope)
              ret.push({ key: "scope", sortable: true, label: 'Echelon'});
            if(this.canEdit)
                ret.push({ key:"edit", sortable:false, label: ''});
            if(!this.showTechAndArtForJudge){
                ret.push({ key:'judge', sortable: false, label: 'Juge', class:"judge"});
            } else {
                ret.push({ key: 'techJudge', sortable:false, label: 'Jude tech.', class:"judge"});
                ret.push({ key: 'artJudge', sortable:false, label: 'Jude art.', class:"judge"});
            }
            ret.push({ key:'fieldDirector', sortable: false, label: 'DT', class:"fieldDirector"});
            ret.push({ key:'scorer', sortable: false, label: 'Scoreur', class:"scorer"});
            ret.push({ key:'teacher', sortable: false, label: 'Formatteur', class:'teacher'});
            if(!this.showTechAndArtForJudge){
                ret.push({ key:'judgeShadow', sortable: false, label: 'Juge ombre', class:"judgeShadow"});
            } else {
                ret.push({ key: 'techJudgeShadow', sortable:false, label: 'Jude tech. ombre', class:"judgeShadow"});
                ret.push({ key: 'artJudgeShadow', sortable:false, label: 'Jude art. ombre', class:"judgeShadow"});
            }
            ret.push({ key:'fieldDirectorShadow', sortable: false, label: 'DT ombre', class: "fieldDirectorShadow"});
            ret.push({ key:'scorerShadow', sortable: false, label: 'Scoreur ombre', class: "scorerShadow"})
            ret.push({ key:'teacherShadow', sortable: false, label: 'Formatteur ombre', class:"teacherShadow"})
            return ret;
        },
        itemsList(){
            var ret = [];

            if(this.items !== null)
                ret = this.items.map(element => {
                    return {
                        id : element.id,
                        scope: element.scope,
                        SCOPE: REF_SCOPE.query().where("code", element.scope).first(),
                        competition_type: element.competition_type,
                        COMPETITION_TYPE: REF_COMPETITION_TYPE.query().where("code", element.competition_type).first(),
                        isJudge: element.isJudge,
                        isTechJudge: element.isTechJudge,
                        isArtJudge: element.isArtJudge,
                        isFieldDirector: element.isFieldDirector,
                        isScorer: element.isScorer,
                        isJudgeShadow: element.isJudgeShadow,
                        isTechJudgeShadow: element.isTechJudgeShadow,
                        isArtJudgeShadow: element.isArtJudgeShadow,
                        isFieldDirectorShadow: element.isFieldDirectorShadow,
                        isScorerShadow: element.isScorerShadow,
                        isTeacher: element.isTeacher,
                        isTeacherShadow: element.isTeacherShadow
                    }
                });
            return ret;
        }
    },
    methods:{
/*        edit(member){
            this.$emit('edit', member.id);
        },
*/
        selectRole(rowItem, roleName){
          var newValue = false;
          switch(roleName)
          {
              case "judge": newValue = rowItem.isJudge; break;
              case "judge_shadow": newValue = rowItem.isJudgeShadow; break;
              case "tech_judge": newValue = rowItem.isTechJudge; break;
              case "tech_judge_shawdow": newValue = rowItem.isTechJudgeShadow; break;
              case "art_judge": newValue = rowItem.isArtJudge; break;
              case "art_judge_shadow": newValue = rowItem.isArtJudgeShadow; break;
              case "field_director": newValue = rowItem.isFieldDirector; break;
              case "field_director_shadow": newValue = rowItem.isFieldDirectorShadow; break;
              case "scorer": newValue = rowItem.isScorer; break;
              case "scorer_shadow": newValue = rowItem.isScorerShadow; break;
              case "teacher": newValue = rowItem.isTeacher; break;
              case "teacher_shadow": newValue = rowItem.isTeacherShadow; break;
          }
            this.$emit('roleItem_change', { roleName: roleName, competition_type: rowItem.competition_type, scope: rowItem.scope, newValue: newValue});
        },
    }
}
</script>

<style scoped>
    span.invalid{ color: var(--danger) !important; font-style: italic; }
    @media screen and (max-width: 767px) and (orientation: portrait) {
        th.federalNumber { font-size: 0.8rem; }
        td.federalNumber { font-size: 0.8rem; }
    }
</style>

import { v4 as uuid } from "uuid";

import { STAFF, STAFF_CAPABILITY, PEOPLE, REF_COMPETITION_TYPE, REF_SCOPE } from "@/LocalDB";

import SynchroHelper from "./synchroHelper";
import PeopleEditHelper from "./peopleEditHelper";

const StaffEditionHelper = {
  changeFirstname(staff_id, newFirstname )
  {
    var staff = STAFF.query().where("id", staff_id).first();
    return (staff != null)
                ? PeopleEditHelper.changeFirstname(staff.id, newFirstname)
                : false;
  },

  changeLastname(staff_id, newLastname )
  {
    var staff = STAFF.query().where("id", staff_id).first();
    return (staff != null)
                ? PeopleEditHelper.changeLastname(staff.id, newLastname)
                : false;
  },

  changeFederalNumber(staff_id, newFederalNumber )
  {
    var staff = STAFF.query().where("id", staff_id).first();
    return (staff != null)
                ? PeopleEditHelper.changeFederalNumber(staff.id, newFederalNumber)
                : false;
  },

  changeSexe(staff_id, newSexe )
  {
    var staff = STAFF.query().where("id", staff_id).first();
    return (staff != null)
                ? PeopleEditHelper.changeSexe(staff.id, newSexe)
                : false;
  },

  changeBirthdate(staff_id, newBirthdate )
  {
    var staff = STAFF.query().where("id", staff_id).first();
    return (staff != null)
                ? PeopleEditHelper.changeBirthdate(staff.id, newBirthdate)
                : false;
  },

  setPeopleAsStaffMember(people_id)
  {
    var existing = STAFF.query().where("id", people_id).first();
    if(existing == null)
    {
      var people = PEOPLE.query().where("id", people_id).first();
      if(people != null)
      {
        var insert = {
          id: people_id,
          isFederalActive: true,
        }
        STAFF.insert({ data: insert });
        SynchroHelper.LogOperation("INSERT", "STAFF", null, insert);
        var change = {
          isStaff: true
        }
        PEOPLE.update({ where: people_id, data: change});
        SynchroHelper.LogOperation("UPDATE", "STAFF", people_id, change);
        SynchroHelper.TryToSynchronize();
        this.initializeCapabilities(people_id);
        return STAFF.query().where("id", people_id).first();
      }
    }
    return null;
  },

  createStaffMember(firstname, lastname, federalNumber, sexe){
    var people = PeopleEditHelper.createPeople(firstname, lastname, federalNumber, sexe, true);
    if(people != null)
    {
      var insert = {
        id: people.id,
        isFederalActive: true,
      };
      STAFF.insert({ data: insert });
      SynchroHelper.LogOperation("INSERT", "STAFF", null, insert);
      SynchroHelper.TryToSynchronize();
      this.initializeCapabilities(people.id);
      return STAFF.query().where("id", people.id).first();
    }
    return null;
  },

  initializeCapabilities(staff_id){
    var staffMember = STAFF.query().where("staff_id", staff_id).first();
    if(staffMember == null) return;
    var scopes = REF_SCOPE.all();
    var competitions_types = REF_COMPETITION_TYPE.all();
    competitions_types.forEach(competition_type => {
      scopes.forEach(scope => {
        var capability = STAFF_CAPABILITY.query().where("staff_id", staff_id).where("competition_type", competition_type.code).where("scope", scope.code).first();
        if(capability == null)
        {
          var change = {
            id: uuid(),
            staff_id: staff_id,
            competition_type : competition_type.code,
            scope: scope.code,
            isJudge : false,
            isJudgeShadow : false,
            isTechJudge : false,
            isTechJudgeShadow : false,
            isArtJudge : false,
            isArtJudgeShadow : false,
            isFieldDirector : false,
            isFieldDirectorShadow : false,
            isScorer : false,
            isScorerShadow : false,
            isTeacher : false,
            isTeacherShadow : false,
          };
          STAFF_CAPABILITY.insert({ data: change });
          SynchroHelper.LogOperation("INSERT", STAFF_CAPABILITY, null, change);
        }
      });
    });
    SynchroHelper.TryToSynchronize();
  },

  setRole(staff_id, competition_type, scope, role, value = true){
    var staff = STAFF.query().where("id", staff_id).first();
    if(staff == null){
      staff = this.setPeopleAsStaffMember(staff_id);
    }
    if(staff != null)
    {
      var capability = STAFF_CAPABILITY.query().where("staff_id", staff_id).where("competition_type", competition_type).where("scope", scope).first();
      var change = {
                      id: (capability == null) ? uuid() : capability.id,
                      staff_id: staff_id,
                      competition_type: competition_type,
                      scope: scope
                   };
      if(capability == null)
      {
        change.isJudge = false;
        change.isJudgeShadow = false;
        change.isTechJudge = false;
        change.isTechJudgeShadow = false;
        change.isArtJudge = false;
        change.isArtJudgeShadow = false;
        change.isFieldDirector = false;
        change.isFieldDirectorShadow = false;
        change.isScorer = false;
        change.isScorerShadow = false;
        change.isTeacher = false;
        change.isTeacherShadow = false;
      }
      switch(role.toLowerCase()){
        case "judge": change.isJudge = value, change.isJudgeShadow = false; break;
        case "judge_shadow": change.isJudgeShadow = value; change.isJudge = false; break;
        case "tech_judge": change.isTechJudge = value; change.isTechJudgeShadow = false;  break;
        case "tech_judge_shadow": change.isTechJudgeShadow = value; change.isTechJudge = false; break;
        case "art_judge": change.isArtJudge = value; change.isArtJudgeShadow = false; break;
        case "art_judge_shadow": change.isArtJudgeShadow = value; change.isArtJudge = false; break;
        case "field_director": change.isFieldDirector = value; change.isFieldDirectorShadow = false; break;
        case "field_director_shadow": change.isFieldDirectorShadow = value; change.isFieldDirector = false; break;
        case "scorer": change.isScorer = value; change.isScorerShadow = false; break;
        case "scorer_shadow": change.isScorerShadow = value; change.isScorer = false; break;
        case "teacher": change.isTeacher = value; change.isTeacherShadow = false; break;
        case "teacher_shadow": change.isTeacherShadow = value; change.isTeacher = false; break;
      }
      if(capability == null)
      {
        STAFF_CAPABILITY.insert({ data : change});
        SynchroHelper.LogOperation("INSERT", "STAFF_CAPABILITY", null, change);
      } else {
        STAFF_CAPABILITY.update({ where: capability.id, data: change});
        SynchroHelper.LogOperation("UPDATE", "STAFF_CAPABILITY", capability.id, change, role.toUpperCase());
      }
      SynchroHelper.TryToSynchronize();
    }
  }
}

export default StaffEditionHelper;

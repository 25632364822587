import { v4 as uuid } from "uuid";

import { PEOPLE } from "@/LocalDB";
import SynchroHelper from "./synchroHelper";

const PeopleEditHelper = {

  isValidLicenseeNumber(number){
    return (number && number!=null && number.length == 8 && number.matches(/^\d{7}[a-zA-Z]$/));
  },
  
  createPeople(firstname, lastname, federalNumber, sexe = "M", isStaff = false){
    var record = {
      id: uuid(),
      firstname: firstname,
      lastname: lastname,
      federalNumber: federalNumber,
      sexe: (sexe == null) ? "M" : "F",
      isStaff: (isStaff == null) ? false : isStaff
    }
    PEOPLE.insert({data: record});
    SynchroHelper.LogOperation("INSERT", "PEOPLE", null, record);
    SynchroHelper.TryToSynchronize();
    return PEOPLE.query().where("id", record.id).first();
  },

  //createAnAccountForPeople(people_id, email, defaultPassword){
  //},

  changeFirstname(people_id, newFirstname)
  {
    var people = PEOPLE.query().where("id", people_id).first();
    if(people != null){
      var change = { firstname: newFirstname};
      PEOPLE.update({ where: people_id, data: change });
      SynchroHelper.LogOperation("UPDATE", "PEOPLE", people_id, change, "CHANGE_FIRSTNAME" );
      SynchroHelper.TryToSynchronize();
      return true;
    }
    return false;
  },

  changeLastname(people_id, newLastname)
  {
    var people = PEOPLE.query().where("id", people_id).first();
    if(people != null){
      var change = { lastname: newLastname};
      PEOPLE.update({ where: people_id, data: change });
      SynchroHelper.LogOperation("UPDATE", "PEOPLE", people_id, change, "CHANGE_LASTNAME" );
      SynchroHelper.TryToSynchronize();
      return true;
    }
    return false;
  },

  changeFederalNumber(people_id, newFederalNumber)
  {
    var people = PEOPLE.query().where("id", people_id).first();
    if(people != null){
      var change = { federalNumber: newFederalNumber};
      PEOPLE.update({ where: people_id, data: change });
      SynchroHelper.LogOperation("UPDATE", "PEOPLE", people_id, change, "CHANGE_FEDERAL_NUMBER" );
      SynchroHelper.TryToSynchronize();
      return true;
    }
    return false;
  },

  changeSexe(people_id, newSexe)
  {
    var people = PEOPLE.query().where("id", people_id).first();
    if(people != null){
      var change = { sexe: newSexe};
      PEOPLE.update({ where: people_id, data: change });
      SynchroHelper.LogOperation("UPDATE", "PEOPLE", people_id, change, "CHANGE_SEXE" );
      SynchroHelper.TryToSynchronize();
      return true;
    }
    return false;
  },

  changeBirthdate(people_id, newBirthdate){
    var people = PEOPLE.query().where("id", people_id).first();
    if(people != null){
      var change = { birthdate: newBirthdate};
      PEOPLE.update({ where: people_id, data: change });
      SynchroHelper.LogOperation("UPDATE", "PEOPLE", people_id, change, "CHANGE_BIRTHDATE" );
      SynchroHelper.TryToSynchronize();
      return true;
    }
    return false;
  },
}

export default PeopleEditHelper;
